body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

//palette
$green: #27bd6f;
$light-peach: #ffecd9;
$peach: #ee786e;
$sand: #fceeb5;
$white: #fff;
$bg:#fff;

.App{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color:$bg;
  width: 100%;
}

//Helpers
.centered-flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-size{
  width: 100%;
  height: 100%;
}

.front{
  z-index: 9999;
}

.headline{
  @extend .front;
  margin-top: 80px;
  margin-bottom: 0px;
  font-size: 4.9rem;
}

button{
  border-radius: 8px;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .icon{
    width: 40px;
    height: 40px;
    vertical-align: middle;
    display: inline-block;
  }
}

.primary{
  background: $green;
  transition: background 1s;
  &:hover{
    background: $peach;
  }
}

.secondary{
  background: #062e56;
  transition: background 1s;
  &:hover{
    background: $peach;
  }
}

h1, h2, h3{
  color:#fff;
  text-shadow: 2px 2px #868686;

}

@media(max-width:600px){
  .headline{
    margin-top: 20px;
    font-size: 2.2rem;
  }
}

@import "./background";
@import "./intro.scss";
@import "./interaction";
